import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const searching = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Searching Programs"
          description="Programs of Searching under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>

        <section className="noSelect">
          <Link to="/dsa/">
            <button type="button" className="back-btn noSelect">
              <span>{"<"}= More Programs</span>
            </button>
          </Link>
        </section>

        <div className="programming-project">
          <h1>Searching</h1>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 2</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Linear Search </h3>
                </div>
                <p className="basic-op">
                  •Sequential search is made over all items one by one
                  <br />
                  •All we have to do is find the match
                  <br />
                  •Also check its efficiency by calculating No. of iterations
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Searching/Linear_Search.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Binary Search </h3>
                </div>
                <p className="basic-op">
                  •Comparing an element in the middle of the array
                  <br />
                  •Further search continues in the lower half of the array
                  <br />
                  •Also check its efficiency by calculating No. of iterations
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Searching/Binary_Search.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default searching
